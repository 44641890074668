(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-coupon-query-params.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-coupon-query-params.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useLocation,
  useHistory
} = ReactRouterDOM;
const {
  useEffect,
  useState,
  useMemo
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  resetWithCouponValues
} = svs.components.tipsen.engine.actions;
const {
  SYSTEM_RSYS,
  SYSTEM_USYS
} = svs.components.tipsen.engine.constants.systems;
const {
  isValidSportkryssNumber,
  generateSportkryssNumber
} = svs.components.tipsen.engine.utils;
const {
  systems
} = svs.components.sport;
const {
  selectWeeks,
  selectBetAmounts,
  selectSportkryssFactors,
  selectSportkryssBase,
  selectRSystems,
  selectUSystems,
  selectSystemTypes,
  selectDefaultReduceModeCount,
  selectOutcomesEventTypeId,
  selectReductionSum,
  selectDrawProductIds
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  CouponQueryParams,
  convertStringToSigns,
  convertResultStringToSigns,
  convertReductionStringToValue
} = svs.components.sport.tipsenShared;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  feature
} = svs.core.detect;
const {
  productIds
} = svs.utils.products;
const convertSignsFunction = eventTypeId => {
  switch (eventTypeId) {
    case EventTypeId.EVENT_1X2:
      return convertStringToSigns;
    case EventTypeId.RESULT:
      return convertResultStringToSigns;
    case EventTypeId.GOAL_COUNT:
      return convertStringToSigns;
    default:
      throw new Error("Unsupported eventTypeId ".concat(eventTypeId));
  }
};
const useCouponQueryParams = couponId => {
  const location = useLocation();
  const history = useHistory();
  const draw = useCurrentDraw();
  const engineDefinition = useSelector(selectEngineDefinition);
  const {
    maxBetAmount
  } = engineDefinition;
  const productId = draw.productId;
  const isMultiDrawsDisabled = feature('ft-hvs-disable-multidraws');
  let weeks = useSelector(selectWeeks);
  if (productId === productIds.FULLTRAFF && isMultiDrawsDisabled) {
    weeks = [1];
  }
  const betAmounts = useSelector(selectBetAmounts);
  const sportkryssFactors = useSelector(selectSportkryssFactors);
  const sportkryssBase = useSelector(selectSportkryssBase);
  const rSystems = useSelector(selectRSystems);
  const uSystems = useSelector(selectUSystems);
  const systemTypes = useSelector(selectSystemTypes);
  const defaultReduceModeCount = useSelector(selectDefaultReduceModeCount);
  const eventTypeId = useSelector(selectOutcomesEventTypeId);
  const productReductionSum = useSelector(selectReductionSum);
  const dispatch = useDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let hasChangedCouponValues = false;
    let hasChangedParameters = false;
    const couponValues = {
      signs: [],
      sportkryssNumber: generateSportkryssNumber(),
      reduceModeCount: defaultReduceModeCount
    };
    const changeCouponValue = (field, value) => {
      hasChangedCouponValues = true;
      couponValues[field] = value;
    };
    const deleteCouponParameter = queryParam => {
      hasChangedParameters = true;
      searchParams.delete(queryParam);
    };
    if (searchParams.has(CouponQueryParams.PARAM_SHARE)) {
      deleteCouponParameter(CouponQueryParams.PARAM_SHARE);
    }
    if (searchParams.has(CouponQueryParams.PARAM_BET_AMOUNT)) {
      const betAmount = Number(searchParams.get(CouponQueryParams.PARAM_BET_AMOUNT));
      if (betAmount && betAmount <= maxBetAmount) {
        changeCouponValue('betAmount', betAmount);
        deleteCouponParameter(CouponQueryParams.PARAM_BET_AMOUNT);
      }
    }
    if (searchParams.has(CouponQueryParams.PARAM_WEEK)) {
      const week = Number(searchParams.get(CouponQueryParams.PARAM_WEEK));
      const weekIndex = weeks.indexOf(week);
      if (weekIndex !== -1) {
        changeCouponValue('week', weekIndex);
        deleteCouponParameter(CouponQueryParams.PARAM_WEEK);
      }
    }
    if (searchParams.has(CouponQueryParams.PARAM_SPORTKRYSS)) {
      const sportkryssAmount = Number(searchParams.get(CouponQueryParams.PARAM_SPORTKRYSS));
      const sportkryssIndex = sportkryssFactors.indexOf(sportkryssAmount / sportkryssBase);
      if (sportkryssIndex !== -1) {
        changeCouponValue('sportkryss', sportkryssIndex);
        deleteCouponParameter(CouponQueryParams.PARAM_SPORTKRYSS);
      }
    }
    if (searchParams.has(CouponQueryParams.PARAM_SPORTKRYSS_NUMBER)) {
      const sportkryssNumber = searchParams.get(CouponQueryParams.PARAM_SPORTKRYSS_NUMBER);
      if (isValidSportkryssNumber(sportkryssNumber)) {
        changeCouponValue('sportkryssNumber', sportkryssNumber);
        deleteCouponParameter(CouponQueryParams.PARAM_SPORTKRYSS_NUMBER);
      }
    }
    if (searchParams.get(CouponQueryParams.PARAM_SIGNS)) {
      const paramSigns = searchParams.getAll(CouponQueryParams.PARAM_SIGNS);
      if (paramSigns.length > 0) {
        const boards = [];
        for (const row of paramSigns) {
          try {
            const convertFunction = convertSignsFunction(eventTypeId);
            boards.push(convertFunction(row, draw.drawEvents.length, eventTypeId, productId));
            deleteCouponParameter(CouponQueryParams.PARAM_SIGNS);
          } catch (_unused) {
            continue;
          }
        }
        changeCouponValue('signs', boards);
      }
    }
    if (searchParams.get(CouponQueryParams.PARAM_REDUCTION)) {
      const reductions = searchParams.getAll(CouponQueryParams.PARAM_REDUCTION);
      if (reductions.length) {
        const boardReduction = [];
        for (const rowReduction of reductions) {
          boardReduction.push(convertReductionStringToValue(rowReduction, productReductionSum, draw.drawEvents.length));
        }
        changeCouponValue('reduction', boardReduction);
      }
      deleteCouponParameter(CouponQueryParams.PARAM_REDUCTION);
    }
    if (searchParams.get(CouponQueryParams.PARAM_SYSTEM_TYPE)) {
      const systemType = searchParams.get(CouponQueryParams.PARAM_SYSTEM_TYPE).toUpperCase();
      if (systemTypes.includes(systemType)) {
        changeCouponValue('systemType', systemTypes.indexOf(systemType));
        deleteCouponParameter(CouponQueryParams.PARAM_SYSTEM_TYPE);
        const system = searchParams.get(CouponQueryParams.PARAM_SYSTEM);
        const systemNum = systems.getSystemNumFromSystemLabel(system);
        if ((systemType === SYSTEM_RSYS || systemType === SYSTEM_USYS) && (rSystems.includes(systemNum) || uSystems.includes(systemNum))) {
          changeCouponValue('rSystem', null);
          changeCouponValue('uSystem', null);
          if (systemType === SYSTEM_RSYS) {
            changeCouponValue('rSystem', systemNum);
          } else {
            changeCouponValue('uSystem', systemNum);
          }
          deleteCouponParameter(CouponQueryParams.PARAM_SYSTEM);
        }
      }
    }
    if (hasChangedCouponValues || hasChangedParameters) {
      if (hasChangedParameters) {
        history.replace({
          search: "?".concat(searchParams)
        });
      }
      if (hasChangedCouponValues) {
        dispatch(resetWithCouponValues(_objectSpread({
          couponId
        }, couponValues)));
      }
    }
  }, [couponId, dispatch, draw.drawEvents.length, eventTypeId, betAmounts, weeks, sportkryssBase, sportkryssFactors, rSystems, uSystems, systemTypes, defaultReduceModeCount, location.search, history, maxBetAmount, productReductionSum, productId]);
};
const deserializeOutcomePairsString = queryString => {
  const params = new URLSearchParams(queryString);
  const outcomePairs = params.get(CouponQueryParams.PARAM_OUTCOME_PAIRS).split(';').map(pairSet => {
    const [index, pairs] = pairSet.split(':');
    return pairs.split(',').map(pair => pair.split('-'));
  });
  return outcomePairs;
};

const useCouponQueryParamsLite = _ref => {
  var _draw$drawEvents2;
  let {
    draw
  } = _ref;
  const location = useLocation();
  const [signs, setSigns] = useState([]);
  const [uSigns, setUSigns] = useState([]);
  const [mSigns, setMSigns] = useState([]);
  const [reductions, setReductions] = useState([]);
  const [outcomePairs, setOutcomePairs] = useState([]);
  const [price, setPrice] = useState();
  const [system, setSystem] = useState();
  const eventTypeId = useSelector(selectOutcomesEventTypeId);
  const productReductionSum = useSelector(selectReductionSum);
  useEffect(() => {
    var _draw$drawEvents;
    if (!(draw !== null && draw !== void 0 && (_draw$drawEvents = draw.drawEvents) !== null && _draw$drawEvents !== void 0 && _draw$drawEvents.length)) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get(CouponQueryParams.PARAM_SIGNS)) {
      const paramSigns = searchParams.getAll(CouponQueryParams.PARAM_SIGNS);
      if (paramSigns.length > 0) {
        var _boards$0$signs, _boards$, _boards$0$mSigns, _boards$2, _boards$0$uSigns, _boards$3;
        const boards = [];
        for (const row of paramSigns) {
          try {
            const convertFunction = convertSignsFunction(eventTypeId);
            boards.push(convertFunction(row, draw.drawEvents.length, eventTypeId));
          } catch (_unused2) {
            continue;
          }
        }
        setSigns((_boards$0$signs = boards === null || boards === void 0 || (_boards$ = boards[0]) === null || _boards$ === void 0 ? void 0 : _boards$.signs) !== null && _boards$0$signs !== void 0 ? _boards$0$signs : []);
        setMSigns((_boards$0$mSigns = boards === null || boards === void 0 || (_boards$2 = boards[0]) === null || _boards$2 === void 0 ? void 0 : _boards$2.mSigns) !== null && _boards$0$mSigns !== void 0 ? _boards$0$mSigns : []);
        setUSigns((_boards$0$uSigns = boards === null || boards === void 0 || (_boards$3 = boards[0]) === null || _boards$3 === void 0 ? void 0 : _boards$3.uSigns) !== null && _boards$0$uSigns !== void 0 ? _boards$0$uSigns : []);
      }
    }
    if (searchParams.get('outcomePairs')) {
      const outcomePairs = deserializeOutcomePairsString(location.search);
      setOutcomePairs(outcomePairs);
    }
    if (searchParams.get(CouponQueryParams.PARAM_REDUCTION)) {
      const reductions = searchParams.getAll(CouponQueryParams.PARAM_REDUCTION);
      if (reductions.length) {
        var _boardReduction$;
        const boardReduction = [];
        for (const rowReduction of reductions) {
          boardReduction.push(convertReductionStringToValue(rowReduction, productReductionSum, draw.drawEvents.length));
        }
        setReductions((_boardReduction$ = boardReduction === null || boardReduction === void 0 ? void 0 : boardReduction[0]) !== null && _boardReduction$ !== void 0 ? _boardReduction$ : []);
      }
    }
    if (searchParams.get(CouponQueryParams.PARAM_PRICE)) {
      setPrice(searchParams.get(CouponQueryParams.PARAM_PRICE));
      searchParams.delete(CouponQueryParams.PARAM_PRICE);
    }
    if (searchParams.get(CouponQueryParams.PARAM_SYSTEM)) {
      setSystem(searchParams.get(CouponQueryParams.PARAM_SYSTEM).toUpperCase());
    }
  }, [draw === null || draw === void 0 || (_draw$drawEvents2 = draw.drawEvents) === null || _draw$drawEvents2 === void 0 ? void 0 : _draw$drawEvents2.length, eventTypeId, location.search, price, productReductionSum]);
  return useMemo(() => ({
    mSigns,
    signs,
    reductions,
    outcomePairs,
    uSigns,
    system,
    price
  }), [price, mSigns, outcomePairs, reductions, signs, system, uSigns]);
};
setGlobal('svs.components.tipsen.couponId', {
  useCouponQueryParams,
  useCouponQueryParamsLite
});

 })(window);